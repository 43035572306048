/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Req, Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import StringMask from 'string-mask';
import './index.css';


const contatos = [
    {id: 1, group: "Contato", subject: "Assuntos Gerais", description: "Caso você ainda não seja um usuário da SUNUNDRIVE e queira falar sobre outro assunto."},
    {id: 2, group: "Cadastro", subject: "Problemas durante o cadastro", description: "Para você que esta com dificuldade em realizar/finalizar o seu cadastro."},
    {id: 3, group: "Suporte Tecnico", subject: "Problemas no acesso ao painel - Usuários já cadastrados", description: "Para você que já é um cliente porem esta enfrentando dificuldades em acessar o painel administrativo da sua conta."},
    {id: 4, group: "Comercial", subject: "Solicitação para Planos Empresariais", description: "Para cotação de planos para empresas. Por favor, Informar CNPJ, Numero Aproximado de Usuários e quantidade de espaço em disco necessário."},
    {id: 5, group: "Comercial", subject: "Parcerias Comerciais", description: "Contato para parceria entre empresas, oferta de produtos e/ou soluções, integração com outras plataformas, empresas gateways de pagamento, bancos e/ou fintechs."},
    {id: 6, group: "Influencer", subject: "Novo cadastro Influencer", description: "Para que você é influencer digital, a SUNUNDRIVE possui uma proposta especial para voce. Entre em contato e não esqueça de informar suas redes sociais, numero de seguidores e público."},
    {id: 7, group: "Ouvidoria", subject: "Ouvidoria", description: "Para sugestões, elogios, reclamações ou denúncias. Esse canal de comunicação garante a transparência e o bom funcionamento dos nossos serviços."},
];


export default function Index() {
    const component_name = "sobre";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const [form, setForm] = useState({nome:"", email: "", telefone:"", mensagem:""});
    const loadPrestineRef = useRef(false);
    const [pageLoading, setPageLoading] = useState(false);

    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(".Influencer")
        } else {
            window.w_unloadAll();
        }
     }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        
    }
    //#endregion Init



    //#region Handlers
    const _HandleUpdate = (event) => {
        let value = event.target.value;

       if(event.target.id === "telefone") {
            value = value.replace(/[^\d]+/g, '');
            let formatter = new StringMask("(99) 99999-9999");
            value = formatter.apply(value);
        }

        setForm({...form, [event.target.id]: value });
    }


    const _HandleSubmit = async(name, event) => {
        event.preventDefault();
      
        if(form.nome === "" 
        || form.email === ""
        || form.telefone === ""
        || form.mensagem === "") {
            window.swal("Alerta", "Preencha todo formulário!", "error");
            return;
        }

        setPageLoading(true);
        let _result = await Req("api/Contato/Send", "POST", {...form, ...contatos.find(c => c.id === 6) }, "");
        setPageLoading(false);
        

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Mensagem enviada com sucesso!", "success");
            setTimeout(() => { 
                if(user.token === '') {
                    navigate('/Login'); 
                } else {
                    navigate('/');
                }
            }, 300);
        
        } else {
            window.swal("Alerta", "Não foi possível enviar a mensagem, tente novamente mais tarde!", "warning");
        }
    }
    //#endregion Handlers


    return (
        <>
        <div className="iq-blog-section overview-block-ptb">
            <div className="Influencer container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            <div className="iq-blog-image clearfix">
                                <img src="images/blog/influencer1.jpg" className="img-fluid" alt="Seja nosso influenciador digital" />
                                <ul className="iq-blogtag">
                                <li><a>SEJA NOSSO INFLUENCIADOR DIGITAL</a></li>
                                </ul>

                            </div>
                            <div className="iq-blog-detail">
                                <div className="iq-blog-meta">
                       

                                </div>
                                <div className="blog-content">
        
                                <br />

                                <p><b>*Bem-vindo à Sunundrive!*</b> Imagine trabalhar com uma empresa séria, segura e inovadora, que oferece uma oportunidade única de renda recorrente no setor de armazenamento em nuvem. Estamos em busca de influenciadores digitais como você, que possam expandir ainda mais nossa presença, trazendo soluções práticas e eficazes de backup e segurança digital para um público cada vez maior.</p>
                                <br />


                                <blockquote className="wp-block-quote">
                                    <p><b>SOBRE A SUNUNDRIVE</b><br /> <br />

                                    A Sunundrive é uma plataforma de armazenamento em nuvem com alta segurança e conformidade com a LGPD brasileira, oferecendo planos completos para pessoas e empresas que querem manter seus dados seguros e acessíveis a qualquer momento. Com estrutura de TI hospedada em datacenters de ponta da Oracle no Brasil, garantimos máxima estabilidade, criptografia de ponta a ponta e um sistema transparente e confiável.
                                    <br /> <br />
                                    Nosso diferencial é nosso *Programa de Afiliados*, que permite aos nossos clientes e influenciadores ganharem renda recorrente ao compartilhar a Sunundrive com sua audiência. Mais do que um simples serviço de nuvem, somos uma plataforma de negócios sustentável e uma comunidade de parceiros que crescem juntos!</p>
                                </blockquote>


                                <p>
                                    <div className="ratio ratio-16x9">
                                        <iframe src="https://www.youtube.com/embed/o6gHQ7dUIQ4" title="YouTube video" allowfullscreen></iframe>
                                    </div>
                                </p>
                                

                                <p><b>NOSSOS SERVIÇOS</b><br /> <br />
                                Os planos de backup da Sunundrive vão desde soluções para uso pessoal até expansões profissionais. Oferecemos diversos níveis de armazenamento para atender diferentes necessidades:
                                </p><p>
                                <b>- *Cloud Backup Basic*</b>: Armazenamento seguro e acessível.<br />
                                <b>- *Cloud Backup Expansion*</b>: Para quem precisa de mais espaço com toda a segurança.<br />
                                <b>- *Cloud Backup Max e Premium*</b>: Nossos planos mais robustos para empresas e usuários avançados.<br />
                                </p><p>
                                Todos os planos incluem suporte dedicado e a chance de participação no nosso programa de afiliados, onde você ganha comissões recorrentes e ainda tem a oportunidade de crescer com bonificações exclusivas em diferentes níveis de indicação!
                                </p>

                                <br /> <br />
                                <p><b>VOCÊ GANHANDO MUITO DINHEIRO COM A SUNUNDRIVE COMO AFILIADO/INFLUENCIADOR</b><br /> <br />
                                Na Sunundrive, acreditamos que nosso sucesso é o sucesso dos nossos parceiros. Para você, influenciador digital, as oportunidades são exponenciais! Imagine divulgar um serviço confiável para seu público, sabendo que pode gerar renda contínua enquanto ajuda seus seguidores a protegerem o que é mais importante para eles.
                                </p>  

                                <p>
                                <b>*Como afiliado, você ganha:*</b>
                                <b>- *Comissões recorrentes*</b>: Ganhe em cinco níveis, com valores mensais que aumentam conforme a sua base de indicados cresce.<br />
                                <b>- *Conta gratuita*</b>: Como influenciador, você terá acesso gratuito a nossa plataforma para criar conteúdo autêntico, mostrar a experiência real de uso e conquistar a confiança do seu público.<br />
                                <b>- *Sem necessidade de conta paga*</b>: Você não precisa assinar um plano para começar a divulgar! Basta se cadastrar, acessar sua conta gratuita e começar a divulgar.<br />
                                </p> 

                                <p><b>PAGO POR REELS E OUTRAS FORMAS DE DIVULGAÇÃO</b><br /> <br />
                                Na Sunundrive, sabemos a importância dos conteúdos criativos e autênticos para engajar o público. Por isso, além da renda recorrente das indicações, <b>*você é pago por cada Reels*</b> que criar para divulgar nossos serviços!
                                </p><p>
                                <b>- *Pagamento por Reels*</b>: Para cada Reels criado, você recebe uma bonificação especial. Mostre seu estilo e criatividade, deixando nossos serviços em destaque para seu público de forma natural e envolvente.
                                <b>- *Link de Afiliado na Bio*</b>: Ao colocar nosso link de afiliado em sua bio, você gera ainda mais visibilidade, além de converter diretamente. Esse link é sua chave para alcançar novos seguidores e levá-los a conhecer o Sunundrive.
                                <b>- *Exclusividade e flexibilidade*</b>: Publicações que você controla. Você decide a frequência, e nós damos o suporte necessário para seu conteúdo brilhar!
                                </p><p>
                                Todos os planos incluem suporte dedicado e a chance de participação no nosso programa de afiliados, onde você ganha comissões recorrentes e ainda tem a oportunidade de crescer com bonificações exclusivas em diferentes níveis de indicação!
                                </p>

                                <p>
                                E não para por aí! Estamos abertos a novas ideias e parcerias. Queremos saber como você enxerga o mercado e como podemos construir um trabalho significativo para seu público. Com isso, juntos, vamos fazer com que a Sunundrive se torne uma referência no mercado de armazenamento em nuvem, levando segurança digital ao alcance de todos.
                                </p>

                                <p>
                                <br /><hr /><br /><br />
                                Faça parte de algo inovador, sustentável e rentável! Se você é apaixonado por compartilhar boas soluções e quer transformar isso em um negócio, venha ser nosso influenciador digital e junte-se à Sunundrive.
                                </p>

                                <p>
                                <div role="form" className="wpcf7" id="wpcf7-f790-p785-o1" lang="en-US" dir="ltr">
                            <div className="screen-reader-response"></div>
                            <form name="fuser" onSubmit={(event) => _HandleSubmit('rcontato', event)} autoComplete="off" id="form-contact" className="wpcf7-form"noValidate>
                            
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label className="contato_label">Seu nome</label>
                                        <span className="wpcf7-form-control-wrap first-name"><input type="text" id="nome" name="first-name" value={form.nome} size="40" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="" onChange={_HandleUpdate}/></span>
                                    </div>
                                    
                  
                                    <div className="col-lg-6">
                                        <label className="contato_label">E-mail</label>
                                        <span className="wpcf7-form-control-wrap your-email"><input type="email" id="email" name="email" value={form.email} size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="" onChange={_HandleUpdate}/></span>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="contato_label">Telefone Celular</label>
                                        <span className="wpcf7-form-control-wrap tel-554"><input type="tel" id="telefone" name="telefone" value={form.telefone} mask="(99) 99999-9999" size="40" className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel" aria-invalid="false" placeholder="" onChange={_HandleUpdate}/></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <p> 
                                            <label className="contato_label">Mensagem</label>
                                            <span className="wpcf7-form-control-wrap your-message"><textarea id="mensagem" name="emnsagem" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="" value={form.mensagem} onChange={_HandleUpdate}>{form.mensagem}</textarea></span>
                                        </p>
                                    </div>
                                    <div className="col-lg-12">
                                        <input type="submit" value="Enviar mensagem" className="wpcf7-form-control wpcf7-submit" />
                                    </div>
                                </div>
                                <div className="wpcf7-response-output wpcf7-display-none"></div>
                            </form>
                        </div>
                                </p>
                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                        
                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/dispositivos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Transferência rápida e eficiente de seu dispositivo para nossa nuvem.
                        </div>


                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/seguranca.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Segurança de ponta com alta disponibilidade para seus dados mais valiosos.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/renda.jpg" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Ganhe renda extra mensal com nosso programa de afiliados por indicações que você faz de nossa plataforma.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="images/marketing.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Expanda suas oportunidades com nosso sistema de marketing multinível com até 5 níveis de bonificação por vendas diretas.
                        </div>
                    </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}