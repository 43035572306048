/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import './index.css';



var revapi1, tpj;
function revslider_showDoubleJqueryError(sliderID) {
    var err = "<div className='rs_error_message_box'>";
    err += "<div className='rs_error_message_oops'>Oops...</div>";
    err += "<div className='rs_error_message_content'>";
    err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
    err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' -> 'Advanced' -> 'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
    err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
    err += "</div>";
    err += "</div>";
    window.jQuery(sliderID).show().html(err);
}


export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const { Hub } = useContext(HubContext);
    const [affiliated, setAffiliated] = useState("");
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);

    const navigate = useNavigate();

    //#region Affiliate
    let { Affiliate } = useParams();

   
    //#endregion Affiliate



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        if(Affiliate!=="" 
            && Affiliate!==null 
            &&Affiliate!==undefined 
            &&Affiliate.toLowerCase()!=="login"
            &&Affiliate.toLowerCase()!=="contato"
            &&Affiliate.toLowerCase()!=="cadastro"
            &&Affiliate.toLowerCase()!=="index.html") {
                Cache.Set("affiliate", Affiliate);
        }

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    
    //#endregion Effects


    //#region Init
    const Init = async() => {    
        if (typeof revslider_showDoubleJqueryError === "undefined") {
            
        }

        let _affliate = await Cache.Get("affiliate");
        if(_affliate && _affliate !== "") setAffiliated(_affliate);



        window.jQuery(function() {
            tpj = window.jQuery;
            if (tpj("#rev_slider_1_1").revolution == undefined) {
                revslider_showDoubleJqueryError("#rev_slider_1_1");
            } else {
                revapi1 = tpj("#rev_slider_1_1").show().revolution({
                    jsFileLocation: "js/",
                    sliderLayout: "fullwidth",
                    visibilityLevels: "1240,1024,778,480",
                    gridwidth: 1440,
                    gridheight: 768,
                    spinner: "spinner0",
                    editorheight: "768,546,584,720",
                    responsiveLevels: "1240,1024,778,480",
                    disableProgressBar: "on",
                    navigation: {
                        onHoverStop: false
                    },
                    parallax: {
                        levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30],
                        type: "mouse",
                        origo: "slidercenter",
                        speed: 0
                    },
                    fallbacks: {
                        allowHTML5AutoPlayOnAndroid: true
                    },
                });
            }
        });


    }
    //#endregion Init



    const _HandleGoto = (url) => {
        navigate(url);
    }


    return (
        <>
        <p className="rs-p-wp-fix"></p>
        <rs-module-wrap id="rev_slider_1_1_wrapper" data-alias="qloud1" data-source="gallery" style={{background:'transparent',padding:0,margin:'0px auto',marginTop:0,marginBottom:0}}>
            <rs-module id="rev_slider_1_1" style={{display:'none'}} data-version="6.1.8">
                <rs-slides>
                    <rs-slide data-key="rs-1" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                        <img src="/revslider/assets/shape.png" alt="qloud" title="shape" width="1600" height="707" data-bg="p:center bottom;" data-parallax="off" className="rev-slidebg" data-no-retina />

                        <rs-layer id="slider-1-slide-1-layer-0" class="textgyreadventor" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:132px;y:c;yo:-69px;" data-text="w:normal;s:70;l:0;fw:700;" data-frame_0="x:-50;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style={{zIndex: 8}}><span className="title-company">SUNUNDRIVE</span>
                            <br /> <span className="title-service">Confiável para Seus Arquivos, Rentável para o Seu Bolso.</span>
                        </rs-layer>

                        <a id="slider-1-slide-1-layer-1" className="rs-layer iq-btn-round iq-button rev-btn" href={"https://painel.sunundrive.com/Cadastro/" + (affiliated?affiliated:'')} target="_self" rel="nofollow" data-type="button" data-color="#ffffff" data-rsp_ch="on" data-xy="x:135px;y:471px;" data-text="w:normal;s:18;l:18;fw:500;" data-dim="h:50px;minh:0px;" data-vbility="t,t,t,t" data-padding="t:15;r:30;b:15;l:30;" data-border="bor:10px,10px,10px,10px;" data-frame_0="x:-50;" data-frame_1="st:1000;sp:1000;sR:1000;" data-frame_999="o:0;st:w;sR:7000;" data-frame_hover="bgc:#007aff;bor:10px,10px,10px,10px;sp:100;e:Power1.easeInOut;bri:120%;" style={{zIndex:"10", backgroundClip:"#007aff"}}>Cadastre-se agora
                        </a>

                        <rs-layer id="slider-1-slide-1-layer-2" data-type="image" data-rsp_ch="on" data-xy="x:984px;y:205px;" data-text="w:normal;" data-dim="w:217px;h:126px;" data-frame_0="y:-50;" data-frame_1="st:2400;sp:1000;sR:2400;" data-frame_999="o:0;st:w;sR:5600;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:16}}><img src="/revslider/assets/box3.png" alt="qloud" width="217" height="126" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-3" data-type="image" data-rsp_ch="on" data-xy="x:984px;y:341px;" data-text="w:normal;" data-dim="w:217px;h:126px;" data-frame_0="y:-50;" data-frame_1="st:2400;sp:1000;sR:2400;" data-frame_999="o:0;st:w;sR:5600;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:31}}><img src="/revslider/assets/box3.png" alt="qloud" width="217" height="126" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-4" data-type="image" data-rsp_ch="on" data-xy="x:862px;y:272px;" data-text="w:normal;" data-dim="w:217px;h:126px;" data-frame_0="y:-50;" data-frame_1="st:2400;sp:1000;sR:2400;" data-frame_999="o:0;st:w;sR:5600;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:26}}><img src="/revslider/assets/box3.png" alt="qloud" width="217" height="126" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-5" data-type="image" data-rsp_ch="on" data-xy="x:1108px;y:275px;" data-text="w:normal;" data-dim="w:217px;h:126px;" data-frame_0="y:-50;" data-frame_1="st:2400;sp:1000;sR:2400;" data-frame_999="o:0;st:w;sR:5600;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:21}}><img src="/revslider/assets/box3.png" alt="qloud" width="217" height="126" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-6" data-type="image" data-rsp_ch="on" data-xy="x:985px;y:571px;" data-text="w:normal;" data-dim="w:217px;h:199px;" data-frame_0="y:-50;" data-frame_1="st:1200;sp:1000;sR:1200;" data-frame_999="o:0;st:w;sR:6800;" data-loop_0="x:2px;" data-loop_999="x:-2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:27}}><img src="/revslider/assets/box2.png" alt="qloud" width="217" height="199" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-7" data-type="image" data-rsp_ch="on" data-xy="x:1107px;y:502px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1200;sp:1000;sR:1200;" data-frame_999="o:0;st:w;sR:6800;" data-loop_0="x:2px;" data-loop_999="x:-2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:17}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-8" data-type="image" data-rsp_ch="on" data-xy="x:864px;y:500px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1200;sp:1000;sR:1200;" data-frame_999="o:0;st:w;sR:6800;" data-loop_0="x:2px;" data-loop_999="x:-2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:22}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-9" data-type="image" data-rsp_ch="on" data-xy="x:987px;y:427px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1200;sp:1000;sR:1200;" data-frame_999="o:0;st:w;sR:6800;" style={{zIndex:12}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-10" data-type="image" data-rsp_ch="on" data-xy="x:985px;y:495px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1500;sp:1000;sR:1500;" data-frame_999="o:0;st:w;sR:6500;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:28}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-11" data-type="image" data-rsp_ch="on" data-xy="x:1107px;y:425px;" data-text="w:normal;" data-dim="w:217px;h:199px;" data-frame_0="y:-50;" data-frame_1="st:1500;sp:1000;sR:1500;" data-frame_999="o:0;st:w;sR:6500;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:18}}><img src="/revslider/assets/box2.png" alt="qloud" width="217" height="199" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-12" data-type="image" data-rsp_ch="on" data-xy="x:987px;y:352px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1500;sp:1000;sR:1500;" data-frame_999="o:0;st:w;sR:6500;" style={{zIndex:13}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-13" data-type="image" data-rsp_ch="on" data-xy="x:864px;y:424px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1500;sp:1000;sR:1500;" data-frame_999="o:0;st:w;sR:6500;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:23}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-14" data-type="image" data-rsp_ch="on" data-xy="x:985px;y:418px;" data-text="w:normal;" data-dim="w:217px;h:199px;" data-frame_0="y:-50;" data-frame_1="st:1800;sp:1000;sR:1800;" data-frame_999="o:0;st:w;sR:6200;" data-loop_0="x:2px;" data-loop_999="x:-2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:29}}><img src="/revslider/assets/box2.png" alt="qloud" width="217" height="199" data-no-retina />
                        </rs-layer>
        
                        <rs-layer id="slider-1-slide-1-layer-15" data-type="image" data-rsp_ch="on" data-xy="x:1107px;y:350px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1800;sp:1000;sR:1800;" data-frame_999="o:0;st:w;sR:6200;" data-loop_0="x:2px;" data-loop_999="x:-2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:19}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-16" data-type="image" data-rsp_ch="on" data-xy="x:864px;y:348px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1800;sp:1000;sR:1800;" data-frame_999="o:0;st:w;sR:6200;" data-loop_0="x:2px;" data-loop_999="x:-2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:24}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-17" data-type="image" data-rsp_ch="on" data-xy="x:987px;y:283px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:1800;sp:1000;sR:1800;" data-frame_999="o:0;st:w;sR:6200;" style={{zIndex:14}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-18" data-type="image" data-rsp_ch="on" data-xy="x:985px;y:342px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:2100;sp:1000;sR:2100;" data-frame_999="o:0;st:w;sR:5900;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:30}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-19" data-type="image" data-rsp_ch="on" data-xy="x:1107px;y:273px;" data-text="w:normal;" data-dim="w:217px;h:199px;" data-frame_0="y:-50;" data-frame_1="st:2100;sp:1000;sR:2100;" data-frame_999="o:0;st:w;sR:5900;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:20}}><img src="/revslider/assets/box2.png" alt="qloud" width="217" height="199" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-20" data-type="image" data-rsp_ch="on" data-xy="x:986px;y:206px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:2100;sp:1000;sR:2100;" data-frame_999="o:0;st:w;sR:5900;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:15}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-21" data-type="image" data-rsp_ch="on" data-xy="x:862px;y:272px;" data-text="w:normal;" data-dim="w:217px;h:198px;" data-frame_0="y:-50;" data-frame_1="st:2100;sp:1000;sR:2100;" data-frame_999="o:0;st:w;sR:5900;" data-loop_0="x:-2px;" data-loop_999="x:2px;sp:3500;e:Sine.easeInOut;yym:t;" style={{zIndex:25}}><img src="/revslider/assets/box1.png" alt="qloud" width="217" height="198" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-23" className="rs-pxl-10" data-type="image" data-rsp_ch="on" data-xy="x:1183px;y:388px;" data-text="w:normal;" data-dim="w:269px;h:168px;" data-frame_0="x:50;" data-frame_1="st:3300;sp:1000;sR:3300;" data-frame_999="o:0;st:w;sR:4700;" data-loop_0="x:-10px;" data-loop_999="x:10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:32}}><img src="/revslider/assets/cloud1.png" alt="qloud" width="287" height="179" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-24" className="rs-pxl-10" data-type="image" data-rsp_ch="on" data-xy="x:785px;y:149px;" data-text="w:normal;" data-dim="w:400px;h:211px;" data-frame_0="x:50;" data-frame_1="st:2700;sp:1000;sR:2700;" data-frame_999="o:0;st:w;sR:5300;" data-loop_0="x:-10px;" data-loop_999="x:10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:11}}><img src="/revslider/assets/cloud2.png" alt="qloud" width="400" height="211" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-25" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:136px;y:578px;" data-text="w:normal;s:24;l:30;" data-dim="w:22px;" data-vbility="t,t,t,f" data-frame_0="y:-50;" data-frame_1="st:3700;sp:1000;sR:3700;" data-frame_999="o:0;st:w;sR:4300;" style={{zIndex:37}}><i className="fab fa-gitlab"></i>
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-27" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:185px;y:578px;" data-text="w:normal;s:24;l:30;" data-dim="w:22px;" data-vbility="t,t,t,f" data-frame_0="y:-50;" data-frame_1="st:3600;sp:1000;sR:3600;" data-frame_999="o:0;st:w;sR:4400;" style={{zIndex:36}}><i className="fab fa-cloud-upload"></i>
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-28" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:285px;y:578px;" data-text="w:normal;s:24;l:30;" data-dim="w:22px;" data-vbility="t,t,t,f" data-frame_0="y:-50;" data-frame_1="st:3500;sp:1000;sR:3500;" data-frame_999="o:0;st:w;sR:4500;" style={{zIndex:34}}><i className="fab fa-gitlab"></i>
                        </rs-layer>
                    
                        <rs-layer id="slider-1-slide-1-layer-29" data-type="text" data-color="#ffffff" data-rsp_ch="on" data-xy="x:239px;y:578px;" data-text="w:normal;s:24;l:30;" data-dim="w:22px;" data-vbility="t,t,t,f" data-frame_0="y:-50;" data-frame_1="st:3400;sp:1000;sR:3400;" data-frame_999="o:0;st:w;sR:4600;" style={{zIndex:33}}><i className="fas fa-recycle"></i>
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-30" data-type="image" data-rsp_ch="on" data-xy="x:679px;y:121px;" data-text="w:normal;" data-dim="w:151px;h:81px;" data-frame_0="sX:0.8;sY:0.8;" data-frame_1="e:Power4.easeOut;sp:1000;" data-frame_999="o:0;st:w;sR:8000;" data-loop_0="x:10px;" data-loop_999="x:-10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:35}}><img src="/revslider/assets/banner-pattern-4-1.png" alt="qloud" width="151" height="81" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-31" data-type="image" data-rsp_ch="on" data-xy="x:-32px;y:12px;" data-text="w:normal;" data-dim="w:151px;h:81px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;sR:8000;" data-loop_0="x:-10px;" data-loop_999="x:10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:38}}><img src="/revslider/assets/banner-pattern-3-1.png" alt="qloud" width="151" height="81" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-32" data-type="image" data-rsp_ch="on" data-xy="x:r;y:50px;" data-text="w:normal;" data-dim="w:151px;h:81px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;sR:8000;" data-loop_0="x:10px;" data-loop_999="x:-10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:39}}><img src="/revslider/assets/banner-pattern-5-1.png" alt="qloud" width="151" height="81" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-33" data-type="image" data-rsp_ch="on" data-xy="x:r;xo:-100px;y:b;yo:100px;" data-text="w:normal;" data-dim="w:116px;h:214px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;sR:8000;" data-loop_0="y:-10px;" data-loop_999="y:10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:40}}><img src="/revslider/assets/banner-pattern-2.png" alt="qloud" width="116" height="214" data-no-retina />
                        </rs-layer>

                        <rs-layer id="slider-1-slide-1-layer-34" data-type="image" data-rsp_ch="on" data-xy="x:-100px;y:b;yo:150px;" data-text="w:normal;" data-dim="w:151px;h:289px;" data-frame_0="sX:0.9;sY:0.9;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;sR:8000;" data-loop_0="y:-10px;" data-loop_999="y:10px;sp:5000;e:Sine.easeInOut;yym:t;" style={{zIndex:41}}><img src="/revslider/assets/banner-pattern-1.png" alt="qloud" width="151" height="289" data-no-retina />
                        </rs-layer>

                    </rs-slide>
                </rs-slides>
                <rs-progress className="rs-bottom" style={{visibility: "hidden !important;"}}></rs-progress>
            </rs-module>
        </rs-module-wrap>

        <section className="iq-fancy-box-section">
            <div className="container">

              
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center iq-title-box iq-title-default iq-title-box-2 wow fadeInUp" data-wow-duration="0.6s">
                            {/*<div className="iq-title-icon">
                            </div>
                            
                            <h2 className="iq-title">Como funciona</h2>*/}

                            <a className="cadastre-se-agora iq-btn-round iq-button rev-btn" href={"https://painel.sunundrive.com/Cadastro/" + (affiliated?affiliated:'')} target="_blank">Cadastre-se agora </a>

                        </div>
                    </div>
                </div>

                <div className="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/o6gHQ7dUIQ4" title="YouTube video" allowfullscreen></iframe>
                </div>

                <br />           
                <a className="iq-planos iq-btn-round iq-button rev-btn" href="/Planos" target="_self"> Conheça nossos planos</a>

                <br /><br />
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-md-6 mb-lg-0 mb-5 mt-lg-5">
                        <div className="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp" data-wow-duration="0.6s">
                            <div className="iq-img-area">
                                <img src="/images/fancy-box/01.png" className="img-fluid" alt="QLOUD" /> </div>
                            <div className="iq-fancy-box-content">
                                <h5 className="iq-fancy-title">Backup ágil </h5>
                                <p className="fancy-box-content"> Mova seus arquivos de forma simples e rápida através do nosso site.
                                </p>
                                <div className="iq-btn-container">
                                    <a className="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">
                                    &nbsp;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-lg-0 mb-5 mt-lg-5">
                        <div className="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp" data-wow-duration="1.2s">
                            <div className="iq-img-area">
                                <img src="/images/fancy-box/02.png" className="img-fluid" alt="QLOUD" /> </div>
                            <div className="iq-fancy-box-content">
                                <h5 className="iq-fancy-title">Segurança em Nuvem</h5>
                                <p className="fancy-box-content"> Esteja tranquilo com seus arquivos mais importantes protegidos em servidores na nuvem de alta disponibilidade.
                                </p>
                                <div className="iq-btn-container">
                                    <a className="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">
                                    &nbsp;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-lg-5">
                        <div className="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp " data-wow-duration="1.8s">
                            <div className="iq-img-area">
                                <img src="/images/fancy-box/03.png" className="img-fluid" alt="QLOUD" /> </div>
                            <div className="iq-fancy-box-content">
                                <h5 className="iq-fancy-title">Bonificação Recorrente</h5>
                                <p className="fancy-box-content"> Ganhos recorrentes em cada mensalidade de assinaturas indicadas por você em até 5 níveis de bonificações.
                                </p>
                                <div className="iq-btn-container">
                                    <a className="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">
                                    &nbsp;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}